import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  useParams
} from "react-router-dom";
import './components/includes/Responsive.css'
import './components/KonfiguratorDetail.css'
import { redirect } from "react-router-dom";
import './components/includes/Header.css'
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import Dbip from './components/includes/Dbip';
import './components/includes/FormFileBlock.css'

import LinkData from './components/includes/Links.json'


const KonfiguratorDetail = lazy(()=>import("./components/KonfiguratorDetail"))

function App() {
  const [context, setcontext] = useState()
  let para = useParams()


  let [linkarr,setlinkarr] = useState([])
    
  useEffect(()=>{
  decidethepaths()    
  },[])




  function decidethepaths(){
    if(LinkData){
      let val = ["en","en"]
   
      if(para.count){
        val = [para.count,"en"]
        if(para.count.match("-")){
            val = para.count.split("-")
          }
      }
      switch (val[0]) {
        case "de":
          setlinkarr(LinkData.data.de_arr)
          break;
        case "da":
          setlinkarr(LinkData.data.da_arr)
        break;
        case "en":
          setlinkarr(LinkData.data.globallangarr)
          break;
        case "it":
          setlinkarr(LinkData.data.it_arr)
        break;
        case "es":
          setlinkarr(LinkData.data.es_arr)
          break;
        case "fi":
          setlinkarr(LinkData.data.fi_arr)
        break;
        case "fr":
          setlinkarr(LinkData.data.fr_arr)
          break;
        case "nb":
          setlinkarr(LinkData.data.nb_arr)
        break;
        case "nl":
          setlinkarr(LinkData.data.nl_arr)
          break;
        case "pl":
          setlinkarr(LinkData.data.pl_arr)
        break;
        case "pt":
          setlinkarr(LinkData.data.pt_arr)
          break;
        case "sv":
          setlinkarr(LinkData.data.sv_arr)
        break;
        case "tr":
          setlinkarr(LinkData.data.tr_arr)
          break;
      
        default:setlinkarr(LinkData.data.en_arr)
          break;
      }
  
    }
  }

  useEffect(() => {
    // Define the font URL(s) from your S3 bucket
    const fontUrl = 'https://q-railing.s3.eu-central-1.amazonaws.com/fonts/Inter-Regular.ttf';

    // Create a link element for the font
    const fontLink = document.createElement('link');
    fontLink.href = fontUrl;
    fontLink.rel = 'stylesheet';

    // Append the link to the head of the document
    document.head.appendChild(fontLink);

    // Optionally, you can load multiple fonts by creating additional link elements

    return () => {
      // Clean up the link element when the component unmounts
      document.head.removeChild(fontLink);
    };
  }, []);


  /*
  			0)"/webshop",
        1)"/gelaender/konfigurator/",
        2)"/gelaender/","/gelaender/glasgelaender/",
        3)"/gelaender/treppengelaender/",
        4)"/gelaender/handlaeufe/",
        5)"/gelaender/balkongelaender/",
        6)"/gelaender/franzoesische-balkone/",
        7)"/gelaender/systeme/",
        8)"/gelaender/systeme/:system/",
        9)"/inspiration/",
        10)"/service/",
        11)"/service/kontakt/",
        12)"/service/downloads/",
        13)"/service/schulungen/",
        14)"/service/zertifikate-und-qualitätspruefzeichen/",
        15)"/service/kataloge-und-broschueren/",
        16)"/unternehmen/",
        17)"/unternehmen/kontakt/",
        18)"/unternehmen/karriere/",
        19)"/unternehmen/karriere/stellenangebote/",
        20)"/unternehmen/karriere/ausbildung/",
        21)"/unternehmen/karriere/initiativbewerbung/",
        22)"/unternehmen/ueber-uns/",
        23)"/unternehmen/ueber-uns/geschichte/",
        24)"/unternehmen/ueber-uns/vision-und-mission/",
        24)"/unternehmen/ueber-uns/werte/",
        26)"/unternehmen/aktuelles/",
        27)"/unternehmen/newsletter/",
        28)"/datenschutz/",
        29)"/impressum/",
        30)"/sitemap/"],
  
  
  */


  let sitepath = "configurator"
  let globallangarr = ["/webshop","/balustrades/configurator","/balustrades","/balustrades/glass-balustrades","/balustrades/stair-railing/","/balustrades/handrails/","/balustrades/balcony-railing/"
  ,"/balustrades/juliet-balcony/","/balustrades/systems/","/balustrades/systems/:system/","/inspiration/",
  "/service/","/service/contact/","/service/downloads/","/service/training/","/service/certificates-and-quality-marks/","/service/catalogues-and-brochures/"]



  let en_arr = ["/webshop","/balustrades/configurator","/balustrades","/balustrades/glass-balustrades","/balustrades/stair-railing/","/balustrades/handrails/","/balustrades/balcony-railing/","/balustrades/juliet-balcony/","/balustrades/systems/","/balustrades/systems/:system/"
               ,"/inspiration/","/service/","/service/contact/","/service/downloads/","/service/training/","/service/certificates-and-quality-marks/","/service/catalogues-and-brochures/","/company/","/company/contact/","/company/career/","/company/career/job-opportunities/","/company/career/apprenticeship/","/company/career/open-application/","/company/about-us/","/company/about-us/history/","/company/about-us/vision-and-mission/","/company/about-us/values/","/company/news/","/company/newsletter/","/privacy-policy/","/imprint/","/sitemap/"]

  let de_arr = ["/webshop","/gelaender/konfigurator/","/gelaender/","/gelaender/glasgelaender/","/gelaender/treppengelaender/","/gelaender/handlaeufe/","/gelaender/balkongelaender/","/gelaender/franzoesische-balkone/","/gelaender/systeme/","/gelaender/systeme/:system/"
              ,"/inspiration/","/service/","/service/kontakt/","/service/downloads/","/service/schulungen/","/service/zertifikate-und-qualitätspruefzeichen/","/service/kataloge-und-broschueren/","/unternehmen/","/unternehmen/kontakt/","/unternehmen/karriere/","/unternehmen/karriere/stellenangebote/","/unternehmen/karriere/ausbildung/","/unternehmen/karriere/initiativbewerbung/","/unternehmen/ueber-uns/","/unternehmen/ueber-uns/geschichte/","/unternehmen/ueber-uns/vision-und-mission/","/unternehmen/ueber-uns/werte/","/unternehmen/aktuelles/","/unternehmen/newsletter/","/datenschutz/","/impressum/","/sitemap/"]

  let fr_arr = ["/boutique-en-ligne", "/configurateur/garde-corps/", "/garde-corps/", "/garde-corps/garde-corps-en-verre/", "/garde-corps/garde-corps-d-escalier/", "/garde-corps/main-courante/", "/garde-corps/garde-corps-de-balcon/", "/garde-corps/balcons-francais/", "/garde-corps/systemes/", "/garde-corps/systemes/:systeme/"
            , "/inspiration/","/service/", "/service/contact/", "/service/telechargements/", "/service/formations/", "/service/certificats-et-labels-de-qualite/", "/service/catalogues-et-brochures/","/societe/","/societe/contact/","/societe/carrieres/","/societe/carrieres/opportunites-d-emploi/","/societe/carrieres/apprentissage/","/societe/carrieres/candidature-spontanee/","/societe/a-propos-de-nous/","/societe/a-propos-de-nous/historique/","/societe/a-propos-de-nous/vision-et-mission/","/societe/a-propos-de-nous/valeurs/","/societe/actualites/","/societe/bulletin-d-information/","/declaration-relative-a-la-protection-des-donnees/","/conditions-generales-de-vente-et-de-livraison/","/sitemap/"]

  let nl_arr = ["/webshop", "/balustrade/configurator/", "/balustrade/", "/balustrade/glasbalustrade/", "/balustrade/trapleuningen/", "/balustrade/handrails/", "/balustrade/balkonbalustrade/", "/balustrade/frans-balkon/", "/balustrade/systemen/", "/balustrade/systemen/:systeem/"
            , "/inspiratie/","/service/", "/service/contact/", "/service/downloads/", "/service/trainingen/", "/service/certificaten-en-kwaliteitskeurmerken/", "/service/catalogi-en-brochures/","/bedrijf/","/bedrijf/contact/","/bedrijf/carriere/","/bedrijf/carriere/vacatures/","/bedrijf/carriere/opleiding/","/bedrijf/carriere/open-sollicitatie/","/bedrijf/over-ons/","/bedrijf/over-ons/geschiedenis/","/bedrijf/over-ons/visie-en-missie/","/bedrijf/over-ons/waarden/","/bedrijf/nieuws/","/bedrijf/nieuwsbrief/","/privacybeleid/","/impressum/","/sitemap/"]

  let it_arr = ["/negozio-online", "/ringhiera/configuratore/", "/ringhiera/", "/ringhiera/parapetti-in-vetro/", "/ringhiera/parapetti-per-scale/", "/ringhiera/mancorrenti/", "/ringhiera/balaustra-per-balconi/", "/ringhiera/balconi-francesi/", "/ringhiera/sistemi/", "/ringhiera/sistemi/:sistema/"
            , "/ispirazione/","/servizio/", "/servizio/contatti/", "/servizio/download/", "/servizio/corsi-di-formazione/", "/servizio/certificati-e-marchi-di-qualità/", "/servizio/cataloghi-e-brochure/","/l-azienda/","/l-azienda/contatto/","/l-azienda/carriera/","/l-azienda/carriera/opportunita-di-lavoro/","/l-azienda/carriera/tirocinio/","/l-azienda/carriera/candidatura-libera/","/l-azienda/chi-siamo/","/l-azienda/chi-siamo/storia/","/l-azienda/chi-siamo/visione-e-missione/","/l-azienda/chi-siamo/valori/","/l-azienda/novita/","/l-azienda/newsletter/","/trattamento-dati-personali/","/note-legali/","/sitemap/"];

  let da_arr = ["/webshop", "/gelænder/konfigurator/", "/gelænder/", "/gelænder/glasgelænder/", "/gelænder/trappegelænder/", "/gelænder/håndlister/", "/gelænder/balkongelænder/", "/gelænder/franske-balkoner/", "/gelænder/systemer/", "/gelænder/systemer/:system/"
            , "/inspiration/","/service/", "/service/kontakt/", "/service/downloads/", "/service/kurser/", "/service/certifikater-og-kvalitetsmærker/", "/service/kataloger-og-brochurer/","/virksomhed/","/virksomhed/kontakt/","/virksomhed/karriere/","/virksomhed/karriere/ledige-stillinger/","/virksomhed/karriere/uddannelse/","/virksomhed/karriere/uopfordret-ansøgning/","/virksomhed/om-os/","/virksomhed/om-os/historie/","/virksomhed/om-os/vision-og-mission/","/virksomhed/om-os/værdier/","/virksomhed/nyheder/","/virksomhed/nyhedsbrev/","/databeskyttelse/","/impressum/","/sitemap/"];

  let pt_arr = ["/loja-online", "/corrimao/configurador/", "/corrimao/", "/corrimao/corrimao-de-vidro/", "/corrimao/corrimao-de-escada/", "/corrimao/pegas/", "/corrimao/corrimao-de-varanda/", "/corrimao/varandas-francesas/", "/corrimao/sistemas/", "/corrimao/sistemas/:sistema/"
            , "/inspiracao/","/servico/", "/servico/contato/", "/servico/downloads/", "/servico/cursos/", "/servico/certificados-e-selos-de-qualidade/", "/servico/catalogos-e-brochuras/","/empresa/","/empresa/contatos/","/empresa/carreira/","/empresa/carreira/oportunidades-de-emprego/","/empresa/carreira/estagios/","/empresa/carreira/candidatura-espontanea/","/empresa/sobre-nos/","/empresa/sobre-nos/historia/","/empresa/sobre-nos/visao-e-missao/","/empresa/sobre-nos/valores/","/empresa/noticias/","/empresa/boletim-informativo/","/politica-de-privacidade/","/expediente/","/sitemap/"];

  let sv_arr = ["/webbshop", "/ravare/planerare/", "/ravare/", "/ravare/glasravare/", "/ravare/trappravare/", "/ravare/handracken/", "/ravare/balkongravare/", "/ravare/franska-balkonger/", "/ravare/system/", "/ravare/system/:system/"
            , "/inspiration/","/tjanster/", "/tjanster/kontakt/", "/tjanster/nedladdningar/", "/tjanster/utbildningar/", "/tjanster/certifikat-och-kvalitetsmärken/", "/tjanster/kataloger-och-broschyrer/","/foretaget/","/foretaget/kontakt/","/foretaget/karriar/","/foretaget/karriar/lediga-tjanster/","/foretaget/karriar/praktikant/","/foretaget/karriar/intresseanmälan/","/foretaget/om-oss/","/foretaget/om-oss/historia/","/foretaget/om-oss/vision-och-mal/","/foretaget/om-oss/var-vardegrund/","/foretaget/nyheter/","/foretaget/nyhetsbrev/","/dataskydd/","/juridiskt-meddelande/","/sitemap/"];

  let fi_arr = ["/verkkokauppa", "/kaide/suunnittelija/", "/kaide/", "/kaide/lasikaide/", "/kaide/porraskaide/", "/kaide/kasikahvat/", "/kaide/parvekekaide/", "/kaide/ranskalaiset-ikkunat/", "/kaide/jarjestelmat/", "/kaide/jarjestelma/:jarjestelma/"
            , "/inspiraatio/","/palvelut/", "/palvelut/yhteystiedot/", "/palvelut/lataukset/", "/palvelut/koulutukset/", "/palvelut/sertifikaatit-ja-laatuhyvaksynnät/", "/palvelut/katalogit-ja-esitteet/","/yhtiö/","/yhtiö/yhteystiedot/","/yhtiö/ura/","/yhtiö/ura/tyopaikat/","/yhtiö/ura/koulutus/","/yhtiö/ura/avoimet-hakemukset/","/yhtiö/tietoa-meista/","/yhtiö/tietoa-meista/historia/","/yhtiö/tietoa-meista/visio-ja-missio/","/yhtiö/tietoa-meista/arvomme/","/yhtiö/uutiset/","/yhtiö/uutiskirje/","/privacy-policy/","/oikeudellinen-huomautus/","/sitemap/"];

  let pl_arr =  ["/sklep-internetowy", "/balustrada/konfigurator/", "/balustrada/", "/balustrada/balustrady-szklane/", "/balustrada/balustrady-schodowe/", "/balustrada/poręcze/", "/balustrada/balustrady-balkonowe/", "/balustrada/balkony-francuskie/", "/balustrada/systemy/", "/balustrada/systemy/:system/"
            , "/inspiracje/","/usługi/", "/usługi/kontakt/", "/usługi/pobieranie/", "/usługi/szkolenia/", "/usługi/certyfikaty-i-oznakowania-jakości/", "/usługi/katalogi-i-broszury/","/firma/","/firma/kontakt/","/firma/kariera/","/firma/kariera/oferty-pracy/","/firma/kariera/szkolenia/","/firma/kariera/niezamowiony-wniosek/","/firma/o-nas/","/firma/o-nas/historia/","/firma/o-nas/wizja-i-misja/","/firma/o-nas/wartosci/","/firma/aktualnosci/","/firma/biuletyn/","/polityka-prywatnosci/","/informacja-prawna/","/sitemap/"];

  let tr_arr = ["/web-magaza", "/koruma-korkuluk/konfigurator/", "/koruma-korkuluk/", "/koruma-korkuluk/cam-korkuluk/", "/koruma-korkuluk/merdiven-korkulugu/", "/koruma-korkuluk/el-direkleri/", "/koruma-korkuluk/balkon-korkuluklari/", "/koruma-korkuluk/fransiz-balkonlar/", "/koruma-korkuluk/sistemler/", "/koruma-korkuluk/sistemler/:sistem/"
              , "/ilham/","/hizmetler/", "/hizmetler/iletisim/", "/hizmetler/indirilebilirler/", "/hizmetler/egitimler/", "/hizmetler/sertifikalar-ve-kalite-belgeleri/", "/hizmetler/kataloglar-ve-brosurler/","/sirket/","/sirket/iletisim/","/sirket/kariyer/","/sirket/kariyer/is-ilanlari/","/sirket/kariyer/egitim/","/sirket/kariyer/inisiyatif-uygulamasi/","/sirket/hakkimizda/","/sirket/hakkimizda/tarihce/","/sirket/hakkimizda/vizyon-ve-misyon/","/sirket/hakkimizda/degerler/","/sirket/haberler/","/sirket/bulten/","/veri-koruma/","/künye/","/sitemap/"];

  let es_arr = ["/tienda-en-linea", "/barandilla/configurador/", "/barandilla/", "/barandilla/barandillas-de-vidrio/", "/barandilla/barandillas-para-escaleras/", "/barandilla/pasamanos/", "/barandilla/barandillas-de-balcon/", "/barandilla/balcones-franceses/", "/barandilla/sistemas/", "/barandilla/sistemas/:sistema/"
              ,"/inspiracion/","/servicios/", "/servicios/contacto/", "/servicios/descargas/", "/servicios/capacitaciones/", "/servicios/certificados-y-marcas-de-calidad/", "/servicios/catalogos-y-folletos/","/empresa/","/empresa/contactenos/","/empresa/carrera/","/empresa/carrera/oportunidades-de-empleo/","/empresa/carrera/practicas/","/empresa/carrera/candidatura-abierta/","/empresa/sobre-nosotros/","/empresa/sobre-nosotros/historia/","/empresa/sobre-nosotros/vision-y-mision/","/empresa/sobre-nosotros/valores/","/empresa/noticias/","/empresa/boletin-informativo/","/politica-de-privacidad/","/aviso-legal/","/sitemap/"];

  let nb_arr = ["/nettbutikk", "/rekkverk/konfigurator/", "/rekkverk/", "/rekkverk/glassrekkverk/", "/rekkverk/trapperekkverk/", "/rekkverk/handrekker/", "/rekkverk/balkongrekkverk/", "/rekkverk/franske-balkonger/", "/rekkverk/systemer/", "/rekkverk/systemer/:system/",
  "/inspirasjon/","/tjenester/", "/tjenester/kontakt/", "/tjenester/nedlastinger/", "/tjenester/opplæring/", "/tjenester/sertifikater-og-kvalitetsmerker/", "/tjenester/kataloger-og-brosjyrer/","/selskap/","/selskap/kontakt/","/selskap/karriere/","/selskap/karriere/ledige-stillinger/","/selskap/karriere/opplaring/","/selskap/karriere/apen-soknad/","/selskap/om-oss/","/selskap/om-oss/historie/","/selskap/om-oss/visjon-og-misjon/","/selskap/om-oss/verdier/","/selskap/om-oss/nyheter/","/selskap/nyhetsbrev/","/personvernerklaring/","/legal-notice/","/sitemap/"];
if(window.location.href.match(/\/de-.*\//)){globallangarr = de_arr}
if(window.location.href.match(/\/en-.*\//)){globallangarr = en_arr}
if(window.location.href.match(/\/fr-.*\//)){globallangarr = fr_arr}
if(window.location.href.match(/\/nl-.*\//)){globallangarr = nl_arr}
if(window.location.href.match(/\/it-.*\//)){globallangarr = it_arr}
if(window.location.href.match(/\/da-.*\//)){globallangarr = da_arr}
if(window.location.href.match(/\/pt-.*\//)){globallangarr = pt_arr}
if(window.location.href.match(/\/nb-.*\//)){globallangarr = nb_arr}
if(window.location.href.match(/\/sv-.*\//)){globallangarr = sv_arr}
if(window.location.href.match(/\/fi-.*\//)){globallangarr = fi_arr}
if(window.location.href.match(/\/pl-.*\//)){globallangarr = pl_arr}
if(window.location.href.match(/\/tr-.*\//)){globallangarr = tr_arr}
if(window.location.href.match(/\/es-.*\//)){globallangarr = es_arr}
if(window.location.href.match(/\/es\//)){globallangarr = es_arr}
if(window.location.href.match(/\/en\//)){globallangarr = en_arr}

const queryClient = new QueryClient();




  return (
    <>
    <QueryClientProvider client={queryClient}>
    <Router>

    <Routes>

    <Route path="/" element={<Dbip></Dbip>}  />
    <Route path="/:count/" element={<KonfiguratorDetail></KonfiguratorDetail>}  />
    <Route path="/:count/:title" element={<KonfiguratorDetail></KonfiguratorDetail>}  />
    <Route path="/:count/:title/" element={<KonfiguratorDetail></KonfiguratorDetail>}  />
    </Routes>
      

    </Router>
    </QueryClientProvider>
    
    </>
  );
}

export default App;
