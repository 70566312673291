import React, { useEffect, useState } from 'react'
import HeaderSlim from './HeaderSlim'


function Dbip(props) {


    let[country, setcountry] = useState({ipAddress:'',countryCode:'',countryName:''})
    let jsono
    const combies = ["de-de","nl-nl","fr-lu","en-gb","en-ie",
    "es-es","it-it","de-ch","de-at","fr-fr","nl-be","fr-fr","da-dk","nb-no","sv-se","fi-fi",
    "pt-pt","pl-pl","en-us","pt-br","en-ca","tr-tr"]
    /*
    useEffect(()=>{
    


        fetch('http://api.db-ip.com/v2/free/self')
        .then(res=>res.json())
        .then(json=>{setcountry(json); jsono=json.countryCode ; console.log(json)}).then(console.log("country")).then(()=>{
            console.log(jsono)
            setTimeout(()=>{triggerLoad()},10)
        }).then(()=>finalredir())
    },[])


    

    useEffect(()=>{
        finalredir()

    },[jsono])
 let ipAddresssss
    fetch('https://api.ipify.org/?format=json')
  .then(response => response.json())
  .then(data => {
    const userIPAddress = data.ip;
    ipAddresssss = userIPAddress
    
   console.log(data)
  })*/ 
    loadloc()
    function loadloc(para){
      fetch('https://api.ipgeolocation.io/ipgeo?apiKey='+process.env.REACT_APP_API_KEY)
      .then(response => response.json())
      .then(data => {
       console.log(data)
       let codee = data.country_code2
       let count = 0
       codee = codee.toLowerCase()

        if(!props.trigger){
        for (let i = 0; i < combies.length; i++) {
          let searcher = combies[i].split("-")
          if(searcher[1] == codee){
            count = count+1
            window.location.replace("/"+combies[i]+"/")
          }
        }
        if(count === 0){
          window.location.replace("/en/")
        }}
        if(props.trigger){
          console.log(window.location.href)
          console.log(data)
          for (let i = 0; i < combies.length; i++) {
            let searcher = combies[i].split("-")
            if(searcher[1] == codee){
              count = count+1
             window.location.replace("/"+combies[i]+"/"+window.location.href.match(/([^/]+$)/)[0])
             
            }
          }
          if(count === 0){
            window.location.replace("/en/"+window.location.href.match(/([^/]+$)/)[0])
          }
        }

    
      }).catch(()=>{window.location.replace("/en-gb/")})
    }
    function triggerLoad(){
            var language = window.navigator.userLanguage || window.navigator.language
            console.log(language)
            console.log(country)
            setTimeout(()=>{console.log(country)},1000)
            //window.location.replace("/"+language+"-"+"country.countryCode.toLowerCase()")
    }

    function finalredir(){
        var language = window.navigator.userLanguage || window.navigator.language
        console.log(language)
        console.log(country)
        setTimeout(()=>{console.log(country)},1000)
       window.location.replace("/"+language+"-"+jsono.toLowerCase())

    }

  return (
    <div>
    <HeaderSlim></HeaderSlim>
    <br />
    <br />
   
    </div>
  )
}

export default Dbip