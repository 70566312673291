import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import "./Headernew.css"
import "./Responsive.css"

import {motion, AnimatePresence} from "framer-motion"
import LinkData from './Links.json'

import LinksShop from './LinksShop.json'
let valueee =""


function HeaderSlim(props) {
    const [advancedsearchtoggle, setadvancedsearchtoggle] = useState(false)
    const [passw, setpassw]= useState("")
    const [login, setlogin]= useState("")
    
    const [menu22, setmenu22]= useState([])
    const [menu2, setmenu2]= useState(false)
    let [headerdata,setheaderdata]=useState({data:[]})
    let fetchurl
    const [searchres, setsearchres] = useState({data:[]})
    const [searchresdocs, setsearchresdocs] = useState({data:[]})
    const [searchresarr, setsearchresarr] = useState([])
    const [extracon, setextracon] = useState({data:[]})
    const [langoption, setlangoption] = useState("eng")
    const [headwords, setheadwords] =useState([])
    const [Productword, setProductword] =useState("")
    const [paramscount, setparamscount] =useState("en")
    const [SearchWordLe, setSearchWordLe] =useState()
    let [smallmenutrigger,setsmallmenutrigger] = useState(false)
    let [smallmenu, setsmallmenu] = useState(false)
    let [sufuan, setsufuan] = useState(false)
    let [suggestrender, setsuggestrender] = useState(false)
    let [Docsword, setDocsword] =useState("")
    const [country, setcountry] =useState()
    let[responsivemenu, setresponsivemenu] = useState(false)
    let params = useParams()
    let para = useParams()
    let param = useParams()
    let [linkarr,setlinkarr] = useState([])
    useEffect(()=>{
        decidethepaths()
    },[])
    let token = process.env.REACT_APP_PUBLIC_KEY
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization:`Bearer ${token}`},
      };
    
    useEffect(()=>{
        if(props.url){
            fetchurl = props.url
        }

    },[])


    function decidethepaths(){
        if(LinkData){
          let val = ["en","en"]
          if(para.count){
            val = [para.count,"en"]
            if(para.count.match("-")){
                val = para.count.split("-")
              }
          }

          switch (val[0]) {
            case "de":
              setlinkarr(LinkData.data.de_arr)
              break;
            case "da":
              setlinkarr(LinkData.data.da_arr)
            break;
            case "en":
              setlinkarr(LinkData.data.globallangarr)
              break;
            case "it":
              setlinkarr(LinkData.data.it_arr)
            break;
            case "es":
              setlinkarr(LinkData.data.es_arr)
              break;
            case "fi":
              setlinkarr(LinkData.data.fi_arr)
            break;
            case "fr":
              setlinkarr(LinkData.data.fr_arr)
              break;
            case "nb":
              setlinkarr(LinkData.data.nb_arr)
            break;
            case "nl":
              setlinkarr(LinkData.data.nl_arr)
              break;
            case "pl":
              setlinkarr(LinkData.data.pl_arr)
            break;
            case "pt":
              setlinkarr(LinkData.data.pt_arr)
              break;
            case "sv":
              setlinkarr(LinkData.data.sv_arr)
            break;
            case "tr":
              setlinkarr(LinkData.data.tr_arr)
              break;
          
            default:setlinkarr(LinkData.data.en_arr)
              break;
          }
      
        }
      }
      function advancesearchfield(propsy){
        if(propsy == false){
           setadvancedsearchtoggle(propsy) 
           setsufuan(propsy)
        }
    }
    let thefinalval = "en_INTERNATIONAL"
    if(para.count){
        thefinalval = para.count
        if(para.count.match("-")){
            thefinalval = thefinalval.replace("-","_")
        }
    }   
    
    let linkkk = LinksShop.data[thefinalval]
    const usermenuger = [ "Onlineshop","Konfigurator","Geländer","Inspiration","Service","Account erstellen"]
    const usermenuno = [ "Nettbutikk","Konfigurator","Rekkverk","Inspirasjon","Service","Opprett kont"]
    const usermenuda = [ "Webshop","Konfigurator","Gelænder","Inspiration","Service","Opret konto"]
    const usermenunl = [ "Webshop","Configurator","Balustrades","Inspiratie","Service","Account aanmaken"]
    const usermenuengb = [ "Webshop","Configurator","Balustrades","Inspiration","Service","Create Account"]
    const usermenuenus = [ "Webshop","Configurator","Railings","Inspiration","Service","Create Account"]
    const usermenufi = [ "Verkkokauppa","Konfiguraattori","Kaide","Inspiraatio","Palvelut","Luo tili"]
    const usermenufr = [ "Boutique en ligne","Configurateur","Garde-corps","Inspiration","Service","Créer un compte"]
    const usermenuit= [ "Negozio online","Configuratore","Ringhiere","Ispirazione","Servizio","Crea un account"]
    const usermenupl = [ "Sklep internetowy","Konfigurator","Balustrady","Inspiracje","Serwis","Utwórz konto"]
    const usermenupt = [ "Loja online","Configurador","Guarda-corpos","Inspiração","Serviço"," Criar conta"]
    const usermenues= [ "Webshop","Configurador","Barandillas","Inspiración","Servicio","Crear cuenta"]
    const usermenusv = [ "Webshop","Konfigurator","Räcken","Inspiration","Service","Skapa konto"]
    const usermenutk = [ "Online Mağaza","Yapılandırıcı","Korkuluk","İlham","Hizmetler","Hesap oluştur"]






    let usermenu = [ "Webshop","Configurator","Balustrades","Inspiration","Service","Create Account"];
    let la
    let arrla
    let arrla1 = ""
    if(param.count){  
        la= param.count
        arrla = la.split("-") 
        if(param.count == "en"){arrla = ["en","INTERNATIONAL"]}
        if(param.count == "es"){arrla = ["es","INTERNATIONAL"]}
        if(arrla[1]){
        arrla[1] = arrla[1].toUpperCase()
        arrla1 = arrla[0]+"-"+arrla[1]
        if(arrla[1]== "INTERNATIONAL"){
          arrla1 = arrla[0]
        }
        }
        }else{arrla1="en"}

    let langdataEurope = [["Austria","de-at","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/at.png"],["Belgium","nl-be","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/be.png"],["Brazil","pt-br","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/br.png"],["Canada","en-ca","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/ca.png"],
    ["Denmark","da-dk","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/dk.png"],["Finnland","fi-fi","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/fi.png"],["France","fr-fr","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/fr.png"],["Germany","de-de","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/de.png"],
    ["Ireland","en-ie","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/ie.png"],["Italy","it-it","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/it.png"],["Luxembourg","fr-lu","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/lu.png"],["Netherlands","nl-nl","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/nl.png"],["Norway","nb-no","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/no.png"],
    ["Poland","pl-pl","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/pl.png"],["Portugal","pt-pt","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/pt.png"],["Spain","es-es","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/es.png"],["Sweden","sv-se","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/se.png"]
    ,["Switzerland","de-ch","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/ch.png"],["Turkey","tr-tr","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/tr.png"],["United States","en-us","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/us.png"],["United Kingdom","en-gb","https://q-railing.s3.eu-central-1.amazonaws.com/icons/flags/gb.png"]]
    useEffect(()=>{ 

        let decider = [params.count,"EN"]
    if(params.count){
        if(params.count.match("-")){
            decider = params.count.split("-")    
        }
    }

    




    if(decider[0] == "de"){usermenu = usermenuger}   
    if(decider[0] == "en"){usermenu = usermenu}if(decider[0] == "fr"){usermenu = usermenufr;}
    if(decider[0] == "nb"){usermenu = usermenuno}  
    if(decider[0] == "nl"){usermenu = usermenunl}      
    if(decider[0] == "da"){usermenu = usermenuda}if(decider[0] == "en" && decider[1] == "us"){usermenu = usermenuenus;}if(decider[0] == "en" && decider[1] == "gb"){usermenu = usermenuengb;}
    if(decider[0] == "fi"){usermenu = usermenufi}if(decider[0] == "it"){usermenu = usermenuit;}if(decider[0] == "pl"){usermenu = usermenupl;}if(decider[0] == "pt"){usermenu = usermenupt;}
    if(decider[0] == "es"){usermenu = usermenues} if(decider[0] == "sv"){usermenu = usermenusv;}  if(decider[0] == "tr"){usermenu = usermenutk;}        
    setmenu22(usermenu)
    
    },[])
    let decider = []

    if(params.count){
        if(params.count.match("-")){
            decider = params.count.split("-") 
        }else{
            decider = [params.count,0]
        }
    }else{

        decider = ["en","en"]
    }

   

    if(decider[0] == "de"){usermenu = usermenuger;}   
    if(decider[0] == "en"){usermenu = usermenu;}if(decider[0] == "fr"){usermenu = usermenufr}
    if(decider[0] == "nb"){usermenu = usermenuno}   
    if(decider[0] == "nl"){usermenu = usermenunl;}     
    if(decider[0] == "da"){usermenu = usermenuda;}if(decider[0] == "en" && decider[1] == "us"){usermenu = usermenuenus;}if(decider[0] == "en" && decider[1] == "gb"){usermenu = usermenuengb;}
    if(decider[0] == "fi"){usermenu = usermenufi;}if(decider[0] == "it"){usermenu = usermenuit;}if(decider[0] == "pl"){usermenu = usermenupl;}if(decider[0] == "pt"){usermenu = usermenupt;}
    if(decider[0] == "es"){usermenu = usermenues;} if(decider[0] == "sv"){usermenu = usermenusv;}  if(decider[0] == "tr"){usermenu = usermenutk;}      


    /*
    window.onscroll = function() {myscrollFunction()};
    var navbar = document.querySelector(".Stickcenter");
    var sticky = 10;
    function myscrollFunction() {
      if (window.pageYOffset >= sticky && !advancedsearchtoggle) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
    }*/

    let stickery = true
        


    return(

        <>
        <div className='Header-wrapper HeaderSlim-wrapper'>
        <div className='Stickcenter sticky'>
        <div className='header-top-wrap'>
        <div className='Header-top HeaderSlim' id="Header-top">

<div className='Header-menu1'>

    <ul id="mainmenu">
        <li><a hrefLang={para.count} href={"/"+para.count+"/"}><img src='https://strapiwmc4hstore.blob.core.windows.net/configurator-assets/logo-q-railing.png' width="72" height={"72"} alt="Q-railing logo"/></a></li>

    </ul>
    <ul  id="resp-menu">
        <li>
            <a hrefLang={para.count} href={"/"+para.count+"/"}><img id="responsive-logo" src='https://strapiwmc4hstore.blob.core.windows.net/configurator-assets/logo-q-railing.png' width="72" height={"72"} alt="Responsive icon"/></a></li>

    </ul>

</div>





</div>
        </div>
        </div>
        </div>

        </>

    )
  
}

export default HeaderSlim